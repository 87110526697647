<template>
  <div
    class="product-box-one"
  >
    <div v-if="isLoading">
      <v-skeleton-loader
        type="image"
        height="268"
      />
    </div>
    <div :class="['overflow-hidden p-10 bg-white', { 'rounded': !noBorder }]" v-else>
      <v-row
        align="center"
        no-gutters
      >
        <v-col cols="12" class="flex-shrink-0">
          <div class="position-relative">
            <div
              v-if="discount > 0"
              class="discount-badge"
            >
              {{ $t("off") }} {{ discount }}%
            </div>
            <router-link
              :to="{
                name: 'ProductDetails',
                params: { slug: productDetails.slug },
              }"
              class="text-reset d-block lh-0 text-center"
            >
              <img
                :src="addResourceImage(productDetails.thumbnail_image)"
                :alt="productDetails.name"
                @error="imageFallback($event)"
                class="img-fit h-104px border-r-13"
              />
            </router-link>
          </div>
        </v-col>
        <v-col
          :cols="12"
          class="minw-0 flex-shrink-0"
        >
          <div
            class="d-flex flex-column pt-2"
          >
            <div
              class="fs-20 mb-2 font-price"
            >
              <template v-if="productDetails.base_price > productDetails.base_discounted_price">
                <del class="opacity-40">{{
                  format_price(productDetails.base_price)
                }}</del>
                <span class="fw-500">{{
                  format_price(productDetails.base_discounted_price)
                }}</span>
              </template>
              <template v-else>
                <span class="fw-500">{{
                  format_price(productDetails.base_discounted_price)
                }}</span>
              </template>
            </div>
            <h5
              class="fw-400 mb-2 lh-1-6 fs-12 text-truncate-2 h-48px"
            >
              <router-link
                :to="{
                  name: 'ProductDetails',
                  params: { slug: productDetails.slug },
                }"
                class="text-reset"
              >{{ productDetails.name }}</router-link
              >
            </h5>
            <div class="vi-line"></div>
            <div class="d-flex align-center">
              <div
                class="flex-grow-1 me-1 mt-2"
              >
                <template v-if="productDetails.stock">
                  <button
                    class="text-reset lh-1 align-center d-flex"
                    @click="
                      showAddToCartDialog({
                        status: true,
                        slug: productDetails.slug,
                      })
                    "
                  >
                    <i class="la la-shopping-cart fs-28 ts-05 me-1 text-vi"></i>
                    <span class="fw-700 fs-13 text-vi">{{ $t("buy_now") }}</span>
                  </button>
             
                </template>
                <template v-else>
                  <span class="fw-700 fs-13 opacity-60">{{
                    $t("out_of_stock")
                  }}</span>
                </template>
              </div>
              <div class="mt-2 w-30px h-30px">
                <template v-if="isThisWishlisted(productDetails.id)">
                  <button
                    class="heart-color pt-1"
                    @click="removeFromWishlist(productDetails.id)"
                    type="button"
                  >
                    <i class="la la-heart heart-color ts-02 fs-24"></i>
                  </button>
                </template>
                <template v-else>
                  <button
                    class="heart-color btn-heart heart-bg mt-1"
                    @click="addNewWishlist(productDetails.id)"
                    type="button"
                  > 
                    <i class="la la-heart heart-bg-white fs-16 ts-02"></i>
                    <!-- <i class="la la-heart-o ts-02 fs-18"></i> -->
                  </button>
                </template>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  props: {
    isLoading: { type: Boolean, required: true, default: true },
    noBorder: { type: Boolean, default: false },
    productDetails: { type: Object, required: true, default: () => ({}) },
  },
  data: () => ({}),
  computed: {
    ...mapGetters('wishlist', ['isThisWishlisted']),
    ...mapGetters('cart', ['isThisInCart', 'findCartItemByVariationId']),
    discount() {
      return this.discount_percent(
        this.$props.productDetails.base_price,
        this.$props.productDetails.base_discounted_price
      )
    },
  },
  methods: {
    ...mapActions('wishlist', ['addNewWishlist', 'removeFromWishlist']),
    ...mapActions('cart', ['addToCart', 'updateQuantity']),
    ...mapMutations('auth', ['showAddToCartDialog']),
    addCart() {
      if (!this.$props.productDetails.is_variant) {
        this.addToCart({
          variation_id: this.$props.productDetails.variations[0].id,
          qty: this.$props.productDetails.min_qty,
        })
      }
    },
    updateCart(type, cart_id) {
      this.updateQuantity({
        type: type,
        cart_id: cart_id,
      })
    },
  },
}
</script>

<style scoped>
.p-10 {
  padding: 10px;
}
.h-104px {
  height: 104px;
}
.border-r-13 {
  border-radius: 13px;
}

.font-price {
  color: #FF5722;
}

.vi-line {
  height: 2px;
  background: #95ACFF;
}

.btn-heart {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heart-bg-white {
  color: #fff;
}

.bg-white {
  background: #fff;
}
</style>